<template>
  <v-app>
    <toolbar></toolbar>
    <v-main style="padding-bottom:50px;">
      <router-view/>
    </v-main>
    <v-card flat color="#eeeeee">
      <v-card-actions style="padding:20px 20px">
        <img width="150"
        src="https://grupoficenca.com/img/logo.17c9b543.png"/>
        <p style="font-weight:700;font-size:12px;padding:20px 0 0 20px">
          Ficenca Costa Rica©
        </p>     
        <v-spacer></v-spacer>
                <img width="100"
        src="@/assets/logofiac.png"/>
        <img width="100"
        src="@/assets/michelin.png"/>
      </v-card-actions>
    </v-card>
        <div style="width:100%;background:#eeeeee;padding: 0 0 20px 20px">
          <v-btn icon text small href="mailto:info@grupoficenca.com"
                 style="font-size:14px;">
                <i class="fas fa-envelope"></i>
                </v-btn>

                <v-btn icon text small href="https://goo.gl/maps/DddbeYdvZkqiDzXf8" target="_blank"
                 style="font-size:14px;">
                <i class="fas fa-location-arrow"></i>
                </v-btn>

                <v-btn icon text
                target="_blank" small style="font-size:14px;"
                :href="`https://facebook.com/ficencacostarica`">
                    <strong><i class="fab fa-facebook-f"></i></strong>
                </v-btn>
                <v-btn icon text
                target="_blank" small style="font-size:14px;"
                :href="`https://instagram.com/ficencacostarica`">
                    <strong><i class="fab fa-instagram"></i></strong>
                </v-btn>
                <v-btn icon text
                target="_blank" small style="font-size:14px;"
                :href="`https://www.linkedin.com/company/ficencacr/`">
                    <i class="fab fa-linkedin-in"></i>
                </v-btn>
                <v-btn icon text
                target="_blank" small style="font-size:14px;"
                :href="`https://api.whatsapp.com/send?phone=50670188845`">
                    <strong><i class="fab fa-whatsapp"></i></strong>
                </v-btn>
        </div>        
    <div class="ddff">
      <div style="cursor:pointer;font-size:8px;text-align:center;color:white" @click="irCreador()">
        Brings | Developer
      </div>
    </div>
  </v-app>
</template>

<style>
.ddff{
  background: #165198;padding:10px 0
}
* {
  font-family: "Montserrat";
}
*::selection {
  background: #165198;
  color: #fff;
}
</style>

<script>
import toolbar from './components/toolbar.vue'
export default {
  name: 'App',

  data: () => ({
    //
  }),
  methods:{
    irCreador(){
      window.open("https://api.whatsapp.com/send?phone=584127787228&text=Hola,%20me%20interesa%20crear%20mi%20marca", "_blank")
    }
  },
  components:{
    toolbar
  }
};
</script>
