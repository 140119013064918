import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/Servicios',
    name: 'servicios',
    component: () => import('../views/ServiceView.vue')
  },
  {
    path: '/Trabajos-realizados',
    name: 'Trabajos',
    component: () => import('../views/TrabajosView.vue')
  },
  {
    path: '/realizados',
    name: 'realizados',
    component: () => import('../components/Trabajos/Detalles.vue')
  },
  {
    path: '/Contactenos',
    name: 'Contactenos',
    component: () => import('../components/Contactenos.vue')
  },
  {
    path: '/Equipos',
    name: 'Equipos',
    component: () => import('../views/EquiposView.vue')
  },
  {
    path: '/Categoria',
    name: 'Categoria',
    component: () => import('../components/tienda/parte1.vue')
  },
  {
    path: '/Equipo',
    name: 'Equipo',
    component: () => import('../components/Equipo.vue')
  },
  {
    path: '/controlador-Inteligente',
    name: 'electronico',
    component: () => import('../components/Servicios/Electronico.vue')
  },
  {
    path: '/monitoreo-Remoto',
    name: 'icons',
    component: () => import('../components/Servicios/Icons.vue')
  },
  {
    path: '/alquiler-de-equipos',
    name: 'alquiler',
    component: () => import('../components/Servicios/Alquiler.vue')
  },
  {
    path: '/Proyectos',
    name: 'proyectos',
    component: () => import('../components/Proyectos/index.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router